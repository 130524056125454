.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
  margin: 15px 0px;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row {
  width: 98%;
  position: relative;
}
@media (max-width: 480px) {
  .admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row {
    width: 100%;
  }
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px dashed var(--text-color);
  border-radius: 5px;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content {
  display: flex;
  align-items: center;
  position: relative;
  width: 80%;
}
@media (max-width: 480px) {
  .admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content {
    width: 100%;
  }
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content .icon {
  margin-right: 15px;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content .icon img {
  max-width: 100%;
  height: auto;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content .content-label {
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 22px;
  color: var(--text-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 800px;
  overflow-x: scroll;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content .content-label::-webkit-scrollbar {
  scrollbar-color: rgb(35, 42, 46);
  width: 5px;
  max-height: 3px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0);
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #eaeaea;
  background-color: #505050;
  margin-right: 3px;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list:hover {
  background-color: #797979;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .row-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .row-wrap {
    display: none;
  }
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .project_brief .row-wrap .button {
  background: #2ac6ff;
  border-radius: 4px;
  padding: 9px 23px 11px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
  z-index: unset !important;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-upload-form .files-form-group .input-field-row .input-file-row .file-field {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-uploaded-preview {
  margin-top: 2rem;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-uploaded-preview .flsprev-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.75rem;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-uploaded-preview .flsprev-items .flsprev-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 3px;
  max-width: 100px;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-uploaded-preview .flsprev-items .flsprev-item:hover {
  background-color: #cfcfcf;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-uploaded-preview .flsprev-items .flsprev-item .flprevitimage {
  height: unset;
  max-width: 100px;
  max-height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: top;
     object-position: top;
}
.admfiles .files-wrapper .files-body .files-b-wrapper .files-uploaded-preview .flsprev-items .flsprev-item .flprevitname {
  margin-top: 10px;
  word-wrap: break-word;
}/*# sourceMappingURL=files.css.map */