.dashboard .welcome {
  width: calc(100% - 40px);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f9fafd;
  border: 1px solid #d8e2ef;
  margin-bottom: 20px;
}
.dashboard .welcome .welRight {
  display: flex;
  align-items: center;
}
.dashboard .welcome .welRight p {
  margin-right: 10px;
}
.dashboard .welcome .welRight .calrangecont .calrange {
  padding: 5px 10px;
}
.dashboard .genstats {
  width: calc(100% - 40px);
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #f9fafd;
  border: 1px solid #d8e2ef;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.dashboard .genstats .genscontact,
.dashboard .genstats .gensnewusrs,
.dashboard .genstats .gensnewleads {
  width: 32%;
  padding: 10px;
}
.dashboard .genstats .genscontact .top,
.dashboard .genstats .gensnewusrs .top,
.dashboard .genstats .gensnewleads .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .genstats .genscontact .top .tIc,
.dashboard .genstats .gensnewusrs .top .tIc,
.dashboard .genstats .gensnewleads .top .tIc {
  display: flex;
  align-items: center;
}
.dashboard .genstats .genscontact .top .tIc .tlcIc,
.dashboard .genstats .gensnewusrs .top .tIc .tlcIc,
.dashboard .genstats .gensnewleads .top .tIc .tlcIc {
  height: 10px;
  width: 10px;
  margin-right: 10px;
}
.dashboard .genstats .genscontact .top .tIc span,
.dashboard .genstats .gensnewusrs .top .tIc span,
.dashboard .genstats .gensnewleads .top .tIc span {
  text-transform: capitalize;
}
.dashboard .genstats .genscontact .top .ellIc,
.dashboard .genstats .gensnewusrs .top .ellIc,
.dashboard .genstats .gensnewleads .top .ellIc {
  height: 10px;
  width: 10px;
  margin: 5px;
  cursor: pointer;
}
.dashboard .genstats .gensnewusrs {
  border-left: 1px solid #7a7a7a;
  border-right: 1px solid #7a7a7a;
}/*# sourceMappingURL=dashboard.css.map */