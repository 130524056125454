.app {
  .homecontainer {
    margin: 0 5%;
    padding: 10px 0;
    .homewrapper {
      .homelayout {
        display: flex;
        .hmelayright {
          flex: 1;
        }
      }
    }
  }
}
