.contacts {
  width: 100%;
  position: relative;
  .cWrapper {
    width: 95%;
    margin: 0 auto;
    padding-top: 30px;
    .padmLeadership {
      .padmLTitle {
        margin-bottom: 10px;
      }
      .padmlewrapper {
        .padlcard {
          padding-top: 25px;
          height: calc(200px - 25px);
          width: 200px;
          border-radius: 15px;
          background-color: #fff;
          .padlcimage {
            height: 70px;
            width: 70px;
            overflow: hidden;
            border-radius: 50%;
            margin: 0 auto;
          }
          .padlcName {
            font-size: 1.2rem;
            text-align: center;
          }
          .padlcRole,
          .padlcExpertise,
          .padlcLevel {
            font-size: 1rem;
            text-align: center;
          }
        }
      }
    }
    .section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        margin-bottom: 10px;
      }
      .sec-events {
        .dwld-table {
          border-radius: 24px;
          padding: 10px 20px;
          background-color: #2ac6ff;
          border: 1px solid #2ac6ff;
          position: relative;
          overflow: hidden;
          z-index: 1;
          text-align: center;
          line-height: 19px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          color: rgb(255, 255, 255);
          align-items: center;
          transition: color 0.15s ease-in-out 0s;
          display: inline-block;
          margin: 0 auto;
          &:hover {
            color: #ebebeb;
            background-color: #3bcbff;
            transition: 0.3s;
          }
        }
        // .rows-per-page {
        // }
      }
    }
    .table-wrap {
      width: 100%;
      border: 1px solid var(--text-color);
      border-radius: 14px;
      margin-bottom: 20px !important;
      thead {
        tr {
          cursor: unset !important;
          //   &:hover {
          //     // background-color: var(--text-color);
          //   }
        }
      }
      table,
      thead,
      tbody,
      tr,
      td {
        border-collapse: collapse;
        border-spacing: 0;
      }
      table {
        border-radius: 14px;
        overflow: hidden;
        width: 100%;
        thead,
        tbody {
          tr {
            cursor: pointer;
            &:hover {
              background-color: #ffffff69;
            }
            td {
              padding: 10px;
              span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                line-height: 1.4rem !important;
                font-size: 13px;
                max-height: 6rem;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                word-break: break-word;
                cursor: pointer;
              }
              &:last-child {
                text-align: center;
                width: max-content;
                &:hover {
                  background-color: var(--modal-bg) !important;
                }
                .actions-wrapp {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  flex-wrap: nowrap;
                  align-content: center;
                  justify-content: space-evenly;
                  flex-direction: row;
                  .report-action {
                    height: 20px;
                    width: 20px;
                    fill: #2ac6ff;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .hiderepmod {
    opacity: 0;
    animation-name: fadeOut;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1s;
  }
  .showrepmod {
    opacity: 1;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }
  .reportModal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    min-height: calc(100vh - 65px);
    overflow-y: scroll;
    background-color: var(--modal-bg);
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #2f323900;
    }
    .rmodalWrapper {
      width: 90%;
      height: auto;
      border-radius: 14px;
      margin: 20px auto;
      background-color: var(--content-bg);
      //background-color: #fff;
      position: relative;
      padding: 30px;
      padding-top: 35px;
      .arrleft {
        position: absolute;
        top: 15px;
        left: 15px;
        height: 25px;
        width: 25px;
        cursor: pointer;
        background-color: #fff;
        border-radius: 50%;
        padding: 5px;
      }
      h2 {
        margin-top: 20px;
        font-size: 70px;
        font-weight: 800;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.3rem;
      }
      .reporterdetails {
        display: inline-flex;
        margin-bottom: 10px;
        h4 {
          margin-right: 20px;
        }
      }
      .reportImages {
        width: 100%;
        overflow: hidden;
        margin-top: 30px;
        .samwrapper {
          display: flex;
          flex-wrap: wrap;
          .assetShow {
            max-width: 300px;
            max-height: 400px;
            margin-right: 10px;
            margin-bottom: 10px;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .repoAction {
        border-radius: 14px;
        padding: 10px 20px;
        background-color: #2ac6ff;
        border: 1px solid #2ac6ff;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-align: center;
        line-height: 19px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
        -webkit-box-align: center;
        align-items: center;
        transition: color 0.15s ease-in-out 0s;
        display: inline-block;
        margin: 0px auto;
        margin-top: 25px;
        &:hover {
          color: rgb(109, 216, 255);
          background-color: rgb(233, 233, 233);
          transition: 0.3s;
        }
      }
    }
  }
}

.hide {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.noreportsmodal {
  width: 100%;
  min-height: calc(100vh - 65px);
  overflow-y: scroll;
  background-color: var(--modal-bg);
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #2f323900;
  }
  .norepmainwrapper {
    width: 100%;
    height: 100%;
    .noreportswrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .inboxsvgicon {
        height: 50%;
        width: 50%;
        fill: #ebebeb;
        margin: 0 auto;
      }
      h2 {
        font-size: 36px;
      }
    }
  }
}
