.header {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafd;
  border: 1px solid #d8e2ef;
  padding: 0 20px;
  border-radius: 10px;
  margin: 0 auto;
  .headerLeft {
    display: flex;
    align-items: center;
    img {
      height: 50px;
      width: 70px;
    }
  }
  .headerRight {
    .hrlnksIc {
      height: 15px;
      width: 15px;
      fill: var(--text-color);
      cursor: pointer;
      margin: 0 10px;
    }
  }
}
