.auth {
  height: 100vh;
  .authContainer {
    margin: 0 5%;
    height: 100%;
    .authWrapper {
      position: relative;
      height: 100%;
      .authForm {
        width: 350px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .authformheader {
          display: flex;
          align-items: center;
          margin: 20px 5px;
          padding-bottom: 20px;
          border-bottom: 1px solid #919191;
          .headerLogoLink {
            height: 40px;
            width: 40px;
            margin-right: 20px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          h2 {
            font-size: 2rem;
            font-weight: 800;
          }
        }
        .authentificationform {
          margin-top: 20px;
          .authformgroup {
            position: relative;
            margin: 30px 0;
            margin-bottom: 20px;
            .authinput {
              outline: none;
              border: 1px solid rgb(211, 211, 211);
              padding: 10px 13px;
              box-shadow: none !important;
              border-radius: 5px;
              background-color: rgb(235, 235, 235);
              display: unset !important;
              width: calc(100% - 26px);
              &:not([value='']) {
                background-color: rgb(255, 219, 166);
              }
              &:not([value='']) ~ .floating-label,
              &:focus ~ .floating-label {
                top: -17px;
                left: 8px;
                font-size: 13px;
                opacity: 1;
                color: #000;
              }
            }
            .floating-label {
              position: absolute;
              pointer-events: none;
              top: 10px;
              left: 10px;
              transition: 0.2s ease all;
              opacity: 0.5;
              color: rgb(95, 95, 95);
              span {
                color: red;
              }
            }
          }
          .passresetlink {
            color: #0077ff;
            cursor: pointer;
          }
          .authsubBtn {
            outline: none;
            border: none;
            background-color: #000000;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 500;
            margin: 5px 0;
            padding: 10px 20px;
            width: 100%;
            cursor: pointer;
            margin: 20px 0;
            border-radius: 5px;
            height: 2.6rem;
          }
        }
      }
      .register {
        z-index: 1;
      }
      .login {
        z-index: 2;
        //border: 1px solid #000;
        border-radius: 5px;
        padding: 10px;
      }
    }
  }
}
