.highflex {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4%;
  position: relative;
}
.highflex .loadingAnim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.highflex .loadingAnim .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.highflex .loadingAnim .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0088f0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.highflex .loadingAnim .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.highflex .loadingAnim .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.highflex .loadingAnim .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.highflex .loadingAnim .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.highflex .highblogcard {
  width: 380px;
  overflow: hidden;
  margin-bottom: 30px;
  cursor: pointer !important;
}
.highflex .highblogcard .bloghighImage {
  flex: 0 0 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden !important;
  position: relative;
}
.highflex .highblogcard .bloghighImage .blgEditIc {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  fill: #0088f0;
  cursor: pointer;
}
.highflex .highblogcard .bloghighImage img {
  max-height: 250px;
  height: auto !important;
  border-radius: 0;
  box-shadow: initial;
  padding: 0 !important;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: brightness(90%);
  transition: 0.5s;
  vertical-align: middle;
  border-style: none;
}
.highflex .highblogcard .bloghighImage img:hover {
  margin-top: 0;
  filter: initial;
  box-shadow: initial;
  transform: scale(1.05);
  transition: 0.5s;
}
.highflex .highblogcard .bloghighTitle {
  padding: 10px;
}
.highflex .highblogcard .bloghighTitle h2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
  color: var(--text-color);
}
.highflex .highblogcard .bloghighTitle h2:hover {
  transition: 0.3s;
  color: #0088f0;
}
.highflex .highblogcard .bloghighTitle p {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
}
.highflex .highblogcard .bloghighInfo p {
  margin-left: 10px;
  color: var(--text-color);
}/*# sourceMappingURL=blogs.css.map */