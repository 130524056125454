.contacts {
  width: 100%;
  position: relative;
}
.contacts .cWrapper {
  width: 95%;
  margin: 0 auto;
  padding-top: 30px;
}
.contacts .cWrapper .padmLeadership .padmLTitle {
  margin-bottom: 10px;
}
.contacts .cWrapper .padmLeadership .padmlewrapper .padlcard {
  padding-top: 25px;
  height: 175px;
  width: 200px;
  border-radius: 15px;
  background-color: #fff;
}
.contacts .cWrapper .padmLeadership .padmlewrapper .padlcard .padlcimage {
  height: 70px;
  width: 70px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}
.contacts .cWrapper .padmLeadership .padmlewrapper .padlcard .padlcName {
  font-size: 1.2rem;
  text-align: center;
}
.contacts .cWrapper .padmLeadership .padmlewrapper .padlcard .padlcRole,
.contacts .cWrapper .padmLeadership .padmlewrapper .padlcard .padlcExpertise,
.contacts .cWrapper .padmLeadership .padmlewrapper .padlcard .padlcLevel {
  font-size: 1rem;
  text-align: center;
}
.contacts .cWrapper .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacts .cWrapper .section-title h1 {
  margin-bottom: 10px;
}
.contacts .cWrapper .section-title .sec-events .dwld-table {
  border-radius: 24px;
  padding: 10px 20px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
  margin: 0 auto;
}
.contacts .cWrapper .section-title .sec-events .dwld-table:hover {
  color: #ebebeb;
  background-color: #3bcbff;
  transition: 0.3s;
}
.contacts .cWrapper .table-wrap {
  width: 100%;
  border: 1px solid var(--text-color);
  border-radius: 14px;
  margin-bottom: 20px !important;
}
.contacts .cWrapper .table-wrap thead tr {
  cursor: unset !important;
}
.contacts .cWrapper .table-wrap table,
.contacts .cWrapper .table-wrap thead,
.contacts .cWrapper .table-wrap tbody,
.contacts .cWrapper .table-wrap tr,
.contacts .cWrapper .table-wrap td {
  border-collapse: collapse;
  border-spacing: 0;
}
.contacts .cWrapper .table-wrap table {
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
}
.contacts .cWrapper .table-wrap table thead tr,
.contacts .cWrapper .table-wrap table tbody tr {
  cursor: pointer;
}
.contacts .cWrapper .table-wrap table thead tr:hover,
.contacts .cWrapper .table-wrap table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.4117647059);
}
.contacts .cWrapper .table-wrap table thead tr td,
.contacts .cWrapper .table-wrap table tbody tr td {
  padding: 10px;
}
.contacts .cWrapper .table-wrap table thead tr td span,
.contacts .cWrapper .table-wrap table tbody tr td span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 1.4rem !important;
  font-size: 13px;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
}
.contacts .cWrapper .table-wrap table thead tr td:last-child,
.contacts .cWrapper .table-wrap table tbody tr td:last-child {
  text-align: center;
  width: -moz-max-content;
  width: max-content;
}
.contacts .cWrapper .table-wrap table thead tr td:last-child:hover,
.contacts .cWrapper .table-wrap table tbody tr td:last-child:hover {
  background-color: var(--modal-bg) !important;
}
.contacts .cWrapper .table-wrap table thead tr td:last-child .actions-wrapp,
.contacts .cWrapper .table-wrap table tbody tr td:last-child .actions-wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: row;
}
.contacts .cWrapper .table-wrap table thead tr td:last-child .actions-wrapp .report-action,
.contacts .cWrapper .table-wrap table tbody tr td:last-child .actions-wrapp .report-action {
  height: 20px;
  width: 20px;
  fill: #2ac6ff;
  margin-right: 10px;
}
.contacts .hiderepmod {
  opacity: 0;
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
}
.contacts .showrepmod {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}
.contacts .reportModal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  min-height: calc(100vh - 65px);
  overflow-y: scroll;
  background-color: var(--modal-bg);
}
.contacts .reportModal::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(47, 50, 57, 0);
}
.contacts .reportModal .rmodalWrapper {
  width: 90%;
  height: auto;
  border-radius: 14px;
  margin: 20px auto;
  background-color: var(--content-bg);
  position: relative;
  padding: 30px;
  padding-top: 35px;
}
.contacts .reportModal .rmodalWrapper .arrleft {
  position: absolute;
  top: 15px;
  left: 15px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}
.contacts .reportModal .rmodalWrapper h2 {
  margin-top: 20px;
  font-size: 70px;
  font-weight: 800;
  margin-bottom: 10px;
}
.contacts .reportModal .rmodalWrapper p {
  font-size: 1.3rem;
}
.contacts .reportModal .rmodalWrapper .reporterdetails {
  display: inline-flex;
  margin-bottom: 10px;
}
.contacts .reportModal .rmodalWrapper .reporterdetails h4 {
  margin-right: 20px;
}
.contacts .reportModal .rmodalWrapper .reportImages {
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
}
.contacts .reportModal .rmodalWrapper .reportImages .samwrapper {
  display: flex;
  flex-wrap: wrap;
}
.contacts .reportModal .rmodalWrapper .reportImages .samwrapper .assetShow {
  max-width: 300px;
  max-height: 400px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.contacts .reportModal .rmodalWrapper .reportImages .samwrapper .assetShow img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.contacts .reportModal .rmodalWrapper .repoAction {
  border-radius: 14px;
  padding: 10px 20px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
  margin: 0px auto;
  margin-top: 25px;
}
.contacts .reportModal .rmodalWrapper .repoAction:hover {
  color: rgb(109, 216, 255);
  background-color: rgb(233, 233, 233);
  transition: 0.3s;
}

.hide {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.noreportsmodal {
  width: 100%;
  min-height: calc(100vh - 65px);
  overflow-y: scroll;
  background-color: var(--modal-bg);
}
.noreportsmodal::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(47, 50, 57, 0);
}
.noreportsmodal .norepmainwrapper {
  width: 100%;
  height: 100%;
}
.noreportsmodal .norepmainwrapper .noreportswrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.noreportsmodal .norepmainwrapper .noreportswrap .inboxsvgicon {
  height: 50%;
  width: 50%;
  fill: #ebebeb;
  margin: 0 auto;
}
.noreportsmodal .norepmainwrapper .noreportswrap h2 {
  font-size: 36px;
}/*# sourceMappingURL=contacts.css.map */