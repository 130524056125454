.blog {
  .blogContainer {
    margin: 0 3%;
    .blogWrapper {
      .blogTitle {
        color: var(--text-color);
      }
      .blogCont {
        .blogForm {
          .blgFormGroup {
            margin: 20px 0;
            .blgInput {
              padding: 15px;
              width: calc(98% - 20px);
              border: none;
              outline: none;
              border-radius: 5px;
              border: 1px solid var(--text-color);
            }
            .blgtxtareamd {
              min-height: 300px;
            }
            .blg_toc_list {
              .blg_toc_list_item {
                padding: 5px 10px;
                background-color: #ffdcb1;
                margin-bottom: 2px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                  font-size: 0.8rem;
                }
                .list_item_remove_ic {
                  height: 10px;
                  width: 10px;
                  cursor: pointer;
                }
              }
            }
            .add_to_toc_box {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              margin-top: 10px;
              .btn_add_to_toc {
                background: #2ac6ff;
                border-radius: 4px;
                padding: 15px 23px;
                font-size: 16px;
                letter-spacing: 1.6px;
                color: rgb(255, 255, 255);
                text-transform: uppercase;
                font-weight: 500;
                z-index: unset !important;
                border: none;
                outline: none;
                cursor: pointer;
              }
            }
          }
          .blgFormGroup_flexxer {
            width: calc(100% - 10px);
            display: flex;
            gap: 20px;
            // .blgInput_toc {
            //   margin-top: 20px;
            // }
          }
          .input-field-row {
            width: calc(50% - 20px);
            display: flex;
            margin-bottom: 22px;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin: 15px 0px;
            .input-file-row {
              width: 98%;
              position: relative;
              @media (max-width: 480px) {
                width: 100%;
              }
              .project_brief {
                display: flex;
                padding: 18px 20px;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                cursor: pointer;
                border: 1px dashed var(--text-color);
                border-radius: 5px;
                .file-content {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  position: relative;
                  width: 80%;
                  @media (max-width: 480px) {
                    width: 100%;
                  }
                  .icon {
                    margin-right: 15px;
                    img {
                      max-width: 100%;
                      height: auto;
                    }
                  }
                  .content-label {
                    font-size: 16px;
                    letter-spacing: -0.17px;
                    line-height: 22px;
                    color: var(--text-primary);
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    //width: 800px;
                    white-space: nowrap;
                    width: min-content;
                    overflow-x: scroll;
                    margin-right: 10px;
                    &::-webkit-scrollbar {
                      scrollbar-color: rgb(35, 42, 46);
                      width: 5px;
                      max-height: 3px;
                      border-radius: 23px;
                      background-color: #ffffff00;
                    }
                    .filenames-list {
                      padding: 8px 15px;
                      border-radius: 23px;
                      font-size: 0.9rem;
                      text-decoration: none;
                      color: #eaeaea;
                      background-color: #505050;
                      margin-right: 3px;
                      width: min-content;
                      white-space: nowrap;
                      &:hover {
                        background-color: #797979;
                      }
                    }
                  }
                }
                .row-wrap {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  @media (max-width: 480px) {
                    display: none;
                  }
                  .button {
                    background: #2ac6ff;
                    border-radius: 4px;
                    padding: 9px 23px 11px;
                    font-size: 16px;
                    letter-spacing: 1.6px;
                    color: rgb(255, 255, 255);
                    text-transform: uppercase;
                    font-weight: 500;
                    z-index: unset !important;
                  }
                }
              }
              .file-field {
                height: 100%;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                width: 100%;
                top: 0px;
                left: 0px;
                cursor: pointer;
              }
            }
          }
          .button {
            all: unset;
            background: #2ac6ff;
            border-radius: 4px;
            padding: 9px 23px 11px;
            font-size: 16px;
            letter-spacing: 1.6px;
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            font-weight: 500;
            z-index: unset !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}
